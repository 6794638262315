<template>
    <div>
        Страница не найдена
    </div>
</template>
<script>
    export default {

    }
</script>
